<template>
  <v-container fluid>
    <v-dialog v-model="dialog" max-width="900px">
      <template v-slot:activator="{ on }">
        <div>
          <span class="mr-5 mb-3" style="font-size: 17px; font-weight: bold"
            >RESPONSABLES</span
          >
          <v-divider vertical></v-divider>

          <v-btn
            color="primary"
            outlined
            rounded
            class="mb-2"
            v-on="on"
            v-if="!readonly"
            >AÑADIR RESPONSABLE <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-divider class="mt-2"></v-divider>
        </div>
      </template>
      <v-card>
        <v-toolbar flat color="primary">
          <v-toolbar-title style="font-size: 17px; color: white">
            Añadir responsable</v-toolbar-title
          >
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="responsible.name"
                label="Nombre(s)"
                dense
                solo
                :error-messages="validationErrors.name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="responsible.lastName"
                label="Apellido(s)"
                dense
                solo
                :error-messages="validationErrors.lastName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="responsible.documentNumber"
                label="Número de identificación"
                type="number"
                minLenght="6"
                min="0"
                dense
                solo
                :error-messages="validationErrors.documentNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="responsible.position"
                label="Cargo"
                dense
                solo
                :error-messages="validationErrors.position"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mr-6"
            rounded
            outlined
            text
            @click="close"
            elevation="2"
            >Cancelar</v-btn
          >
          <v-btn color="primary" size="lg" rounded @click="save">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- v-datatable to list actions and options edit and remove -->
    <v-data-table
      :headers="headers"
      :items="allResponsible"
      class="elevation-1 mb-4 mt-3"
    >
      <template v-slot:item.action="{ item }">
        <v-menu left bottom v-if="!readonly">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="showModalEdit(item.id)">
              <v-list-item-title>
                <v-icon class="mr-2" small>mdi-pencil</v-icon>
                Editar
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="removeResponsible(item.id)">
              <v-list-item-title>
                <v-icon class="mr-2" small>mdi-delete</v-icon>
                Eliminar
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <span class="ml-3 mt-3" v-if="!allResponsible.length">
      <v-icon small color="warning">mdi-alert</v-icon> No se puede crear un plan
      sin ningun responsable</span
    >
  </v-container>
</template>

<script>
import { minLength, required } from "vuelidate/lib/validators";

export default {
  name: "Responsible",
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Apellido", value: "lastName" },
        { text: "Número de identificación", value: "documentNumber" },
        { text: "Cargo", value: "position" },
        { text: "", value: "action", sortable: false },
      ],
      responsible: {
        name: "",
        lastName: "",
        documentNumber: "",
        position: "",
      },
      allResponsible: [],
      isEditing: false,
    };
  },
  validations() {
    return {
      responsible: {
        name: {
          required,
        },
        lastName: {
          required,
        },
        documentNumber: {
          required,
          minLength: minLength(6),
        },
        position: {
          required,
        },
      },
    };
  },
  computed: {
    validationErrors() {
      const validationObject = {
        name: [
          ...[
            !this.$v.responsible.name.required &&
            this.$v.responsible.name.$dirty
              ? "Este campo es requerido"
              : "",
          ],
        ],
        lastName: [
          ...[
            !this.$v.responsible.lastName.required &&
            this.$v.responsible.lastName.$dirty
              ? "Este campo es requerido"
              : "",
          ],
        ],
        documentNumber: [
          ...[
            !this.$v.responsible.documentNumber.required &&
            this.$v.responsible.documentNumber.$dirty
              ? "Este campo es requerido"
              : "",
          ],
          ...[
            !this.$v.responsible.documentNumber.minLength &&
            this.$v.responsible.documentNumber.$dirty
              ? "El número de identificación debe tener al menos 6 caracteres"
              : "",
          ],
        ],
        position: [
          ...[
            !this.$v.responsible.position.required &&
            this.$v.responsible.position.$dirty
              ? "Este campo es requerido"
              : "",
          ],
        ],
      };
      for (const key in validationObject)
        validationObject[key] = validationObject[key].filter((x) => x !== "");

      return validationObject;
    },
  },
  methods: {
    openModal() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.isEditing) {
        this.allResponsible[
          this.allResponsible.findIndex(
            (item) => item.id === this.responsible.id
          )
        ] = this.responsible;
        this.isEditing = false;
      } else {
        const responsible = {
          id: this.allResponsible.length + 1,
          name: this.responsible.name,
          lastName: this.responsible.lastName,
          documentNumber: this.responsible.documentNumber,
          position: this.responsible.position,
        };
        this.allResponsible.push(responsible);
      }
      this.responsible = {
        name: "",
        lastName: "",
        documentNumber: "",
        position: "",
      };
      this.close();
    },
    showModalEdit(id) {
      this.isEditing = true;
      this.responsible = this.allResponsible.find((item) => item.id === id);
      this.openModal();
    },
    removeResponsible(id) {
      const index = this.allResponsible.findIndex(
        (responsible) => responsible.id === id
      );
      this.allResponsible.splice(index, 1);
    },
    getData() {
      return this.allResponsible;
    },
    setResponsible(responsible) {
      this.allResponsible = responsible;
    },
  },
};
</script>
