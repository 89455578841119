export const toSnakeCase = (obj) => {
  if (obj === null || obj === undefined) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => {
      return toSnakeCase(item);
    });
  }
  if (typeof obj === "object") {
    const result = {};
    Object.keys(obj).forEach((key) => {
      const newKey = key.replace(/([A-Z])/g, (g) => {
        return `_${g[0].toLowerCase()}`;
      });
      result[newKey] = toSnakeCase(obj[key]);
    });
    return result;
  }
  return obj;
};

export const toCamelCase = (obj) => {
  if (obj === null || obj === undefined) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => {
      return toCamelCase(item);
    });
  }
  if (typeof obj === "object") {
    const result = {};
    Object.keys(obj).forEach((key) => {
      const newKey = key.replace(/(_\w)/g, (g) => {
        return g[1].toUpperCase();
      });
      result[newKey] = toCamelCase(obj[key]);
    });
    return result;
  }
  return obj;
};
