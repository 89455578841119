<template>
  <v-container fluid>
    <v-dialog v-model="dialog" max-width="900px">
      <template v-slot:activator="{ on }">
        <div>
          <span style="font-size: 17px; font-weight: bold">ACCIONES</span>
          <v-divider class="mr-4" vertical></v-divider>
          <v-btn
            color="primary"
            outlined
            rounded
            class="mb-2"
            v-on="on"
            v-if="!readonly"
            >AÑADIR ACCIONES <v-icon>mdi-plus</v-icon>
          </v-btn>
          <span class="ml-3">
            <v-icon color="#1FB2E1">mdi-information</v-icon> Tenga en cuenta que
            las acciones serán ejecutadas en un tiempo máximo de 4 años, con una
            medición de avance anual.</span
          >
          <v-divider class="mt-2"></v-divider>
        </div>
      </template>
      <v-card>
        <v-toolbar flat color="primary">
          <v-toolbar-title style="font-size: 17px; color: white">
            Añadir acción</v-toolbar-title
          >
        </v-toolbar>

        <v-card-text class="mt-4">
          <!-- row -->
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="action.description"
                label="Acción"
                dense
                solo
                :error-messages="validationErrors.description"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-currency-field
                v-model="action.estimatedCost"
                prefix="$"
                label="Estimación costo (COP)"
                dense
                solo
              ></v-currency-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mr-6"
            rounded
            outlined
            text
            @click="close"
            elevation="2"
            >Cancelar</v-btn
          >
          <v-btn color="primary" size="lg" rounded @click="save">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- v-datatable to list actions and options edit and remove -->
    <v-data-table
      :headers="headers"
      :items="actions"
      :items-per-page="5"
      class="elevation-1 mb-4 mt-3"
    >
      <template v-slot:item.id="{ item }">
        <span class="font-weight-bold">{{ item.id }}</span>
      </template>
      <template v-slot:item.estimatedCost="{ item }">
        <span>{{ item.estimatedCost | currencyCOP }}</span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-menu left bottom v-if="!readonly">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="showModalEdit(item.id)">
              <v-list-item-title>
                <v-icon class="mr-2" small>mdi-pencil</v-icon>
                Editar
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="removeAction(item.id)">
              <v-list-item-title>
                <v-icon class="mr-2" small>mdi-delete</v-icon>
                Eliminar
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <span class="ml-3 mt-3" v-if="!actions.length">
      <v-icon small color="warning">mdi-alert</v-icon> No se puede crear un plan
      sin ninguna acción</span
    >
  </v-container>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "AddAction",
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  validations() {
    return {
      action: {
        description: {
          required,
        },
      },
    };
  },
  computed: {
    validationErrors() {
      const validationObject = {
        description: [
          ...[
            !this.$v.action.description.required &&
            this.$v.action.description.$dirty
              ? "Este campo es requerido"
              : "",
          ],
        ],
      };
      for (const key in validationObject)
        validationObject[key] = validationObject[key].filter((x) => x !== "");
      return validationObject;
    },
  },
  data() {
    return {
      dialog: false,
      action: {
        description: "",
        estimatedCost: 0,
      },
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Acción",
          align: "start",
          sortable: false,
          value: "description",
        },
        {
          text: "Costo estimado",
          align: "start",
          sortable: false,
          value: "estimatedCost",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "action",
        },
      ],
      actions: [],
      isEditing: false,
    };
  },
  methods: {
    openModal() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.isEditing) {
        const action = this.actions.find(
          (action) => action.id === this.action.id
        );
        action.description = this.action.description;
        action.estimatedCost = this.action.estimatedCost;
        this.isEditing = false;
      } else {
        const action = {
          id: this.actions.length + 1,
          description: this.action.description,
          estimatedCost: this.action.estimatedCost,
        };
        this.actions.push(action);
      }
      this.action = {
        description: "",
        estimatedCost: 0,
      };
      this.close();
    },
    removeAction(id) {
      this.actions = this.actions.filter((action) => action.id !== id);
    },
    showModalEdit(id) {
      this.isEditing = true;
      const action = this.actions.find((action) => action.id === id);
      this.action = action;
      this.openModal();
    },
    getData() {
      return this.actions.map((action) => {
        return {
          description: action.description,
          estimatedCost: action.estimatedCost,
        };
      });
    },
    setActions(actions) {
      this.actions = actions;
    },
  },
};
</script>
