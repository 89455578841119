<template>
  <v-container fluid>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <v-icon color="primary">mdi-home</v-icon>
            <v-icon>mdi-chevron-double-right</v-icon>
            PLANES
            <v-icon>mdi-chevron-double-right</v-icon>
            NUEVO PLAN
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </template>
    <template>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 17px">INFORMACIÓN</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-autocomplete
                  :items="allDatasource?.data?.Territorio"
                  item-text="descripcion"
                  item-value="territorio_id"
                  solo
                  label="Territorio"
                  v-model="plan.territory"
                  :error-messages="validationErrors.territory"
                  dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-autocomplete
                  :items="allDatasource?.data?.tipo_territorio"
                  item-text="nombre"
                  item-value="valor"
                  solo
                  label="Tipo territorio"
                  v-model="plan.territoryType"
                  :error-messages="validationErrors.territoryType"
                  dense
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-autocomplete
                  v-model="plan.mandate"
                  :items="allPlanningReference?.mandate"
                  item-text="name"
                  item-value="id"
                  solo
                  label="Nivel de mandato"
                  :error-messages="validationErrors.mandate"
                  dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-autocomplete
                  v-model="plan.mandateYear"
                  :items="allPlanningReference?.years"
                  solo
                  label="Año"
                  :error-messages="validationErrors.mandateYear"
                  dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                  v-model="plan.name"
                  solo
                  label="Titulo del plan"
                  :error-messages="validationErrors.name"
                  dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                  solo
                  v-model="plan.description"
                  label="Mandato"
                  :error-messages="validationErrors.description"
                  dense
              ></v-textarea>
            </v-col>
          </v-row>
          <!-- new row with 3 columns first select multiple, second select solo, next other select solo -->
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-select
                  solo
                  v-model="plan.categories"
                  :items="allPlanningReference?.categories"
                  :menu-props="{ maxHeight: '400' }"
                  hint="seleccionar categoría(s)"
                  item-text="name"
                  item-value="id"
                  label="Categorías"
                  multiple
                  chips
                  persistent-hint
                  :error-messages="validationErrors.categories"
                  dense
              >
                <template #selection="{ item }">
                  <v-chip
                      class="custom-tag"
                      color="#D3E9D7"
                      style="height: auto; margin-top: 11px; margin-left: 5px"
                      close
                      @click:close="removeCategory(item)"
                  >{{ item.name }}
                  </v-chip
                  >
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-select
                  solo
                  v-model="plan.entityResponsible"
                  :items="allPlanningReference?.entity_responsible"
                  :menu-props="{ maxHeight: '400' }"
                  hint="Sistema responsable"
                  item-text="name"
                  item-value="id"
                  label="Sistema responsable"
                  persistent-hint
                  :error-messages="validationErrors.entityResponsible"
                  dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-select
                  solo
                  v-model="plan.fundingSource"
                  :items="allPlanningReference?.funding_source"
                  :menu-props="{ maxHeight: '400' }"
                  hint="Fuente de finanzación"
                  item-text="name"
                  item-value="id"
                  label="Fuente de finanzación"
                  persistent-hint
                  :error-messages="validationErrors.fundingSource"
                  dense
              ></v-select>
            </v-col>
          </v-row>
          <!-- new row with margin top 50px and use component Action-->
          <v-row style="margin-top: 50px">
            <v-col cols="12">
              <Actions ref="actions"></Actions>
            </v-col>
          </v-row>
          <!-- new row with margin top 100px and use component Responsible-->
          <v-row style="margin-top: 100px">
            <v-col cols="12">
              <Responsible ref="responsible"></Responsible>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- card actions with two buttons rounded in center screen one primary "GUARDAR DATOS" with click event submit and other default "CANCELAR" dispatch route back-->
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- make a center buttons-->
          <v-flex class="justify-center mb-5">
            <v-btn color="primary" rounded @click="submit" class="mr-5"
            >GUARDAR DATOS
            </v-btn
            >
            <v-btn rounded @click="$router.go(-1)">CANCELAR</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </template>
  </v-container>
</template>
<script>
import {mapGetters} from "vuex";
import {createPlan} from "@/services/Planning";
import Actions from "./components/Actions.vue";
import Responsible from "./components/Responsible.vue";
import {toSnakeCase} from "../../utils/mapper";
import Swal from "sweetalert2";
import {required} from "vuelidate/lib/validators";

export default {
  components: {
    Actions,
    Responsible,
  },
  data() {
    return {
      plan: {
        territory: null,
        territoryType: null,
        mandate: null,
        mandateYear: null,
        name: null,
        description: null,
        categories: [],
        entityResponsible: null,
        fundingSource: null,
      },
    };
  },
  validations() {
    return {
      plan: {
        territory: {required},
        territoryType: {required},
        categories: {required},
        mandate: {required},
        mandateYear: {required},
        name: {required},
        description: {required},
        entityResponsible: {required},
        fundingSource: {required},
      },
    };
  },
  computed: {
    ...mapGetters(["allDatasource", "allPlanningReference"]),
    validationErrors() {
      const validationObject = {
        territory: [
          ...[
            !this.$v.plan.territory.required && this.$v.plan.territory.$dirty
                ? "Este campo es requerido"
                : "",
          ],
        ],
        territoryType: [
          ...[
            !this.$v.plan.territoryType.required &&
            this.$v.plan.territoryType.$dirty
                ? "Este campo es requerido"
                : "",
          ],
        ],
        categories: [
          ...[
            !this.$v.plan.categories.required && this.$v.plan.categories.$dirty
                ? "Este campo es requerido"
                : "",
          ],
        ],
        mandate: [
          ...[
            !this.$v.plan.mandate.required && this.$v.plan.mandate.$dirty
                ? "Este campo es requerido"
                : "",
          ],
        ],
        mandateYear: [
          ...[
            !this.$v.plan.mandateYear.required &&
            this.$v.plan.mandateYear.$dirty
                ? "Este campo es requerido"
                : "",
          ],
        ],
        name: [
          ...[
            !this.$v.plan.name.required && this.$v.plan.name.$dirty
                ? "Este campo es requerido"
                : "",
          ],
        ],
        description: [
          ...[
            !this.$v.plan.description.required &&
            this.$v.plan.description.$dirty
                ? "Este campo es requerido"
                : "",
          ],
        ],
        entityResponsible: [
          ...[
            !this.$v.plan.entityResponsible.required &&
            this.$v.plan.entityResponsible.$dirty
                ? "Este campo es requerido"
                : "",
          ],
        ],
        fundingSource: [
          ...[
            !this.$v.plan.fundingSource.required &&
            this.$v.plan.fundingSource.$dirty
                ? "Este campo es requerido"
                : "",
          ],
        ],
      };

      for (const key in validationObject)
        validationObject[key] = validationObject[key].filter((x) => x !== "");

      return validationObject;
    },
  },
  methods: {
    removeCategory(category) {
      this.plan.categories = this.plan.categories.filter(
          (item) => item !== category.id
      );
    },
    async submit() {
      this.$v.$touch();
      console.log();
      if (
          this.$v.$invalid ||
          !this.$refs.actions.actions.length ||
          !this.$refs.responsible.allResponsible.length
      )
        return false;
      try {
        const response = await createPlan(this.getInfo());
        if (response.status === 200) {
          this.$router.back();
        }
        Swal.fire({
          icon: "success",
          title: "Plan creado",
          showConfirmButton: false,
          timer: 1500,
        });
        this.resetFields();
        this.$router.go(-1);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Ocurrió un error!",
        });
        console.log(error);
      }
    },
    getInfo() {
      this.plan.actions = toSnakeCase(this.$refs.actions.getData());
      this.plan.responsible = toSnakeCase(this.$refs.responsible.getData());
      this.plan = toSnakeCase(this.plan);
      return this.plan;
    },
    resetFields() {
      this.plan = {
        territory: null,
        territoryType: null,
        mandate: null,
        mandateYear: null,
        name: null,
        description: null,
        categories: [],
        entityResponsible: null,
        fundingSource: null,
      };
    },
  },
  created() {
  },
};
</script>
<style>
.custom-tag > span > .mdi-close-circle::before {
  color: #bb1515 !important;
}
</style>
